import Vue from "vue";
import axios from 'axios';
import router from '@/router'

const state = {
  loadingDataTimes: null,
  itemsDataTimes: [],
}

const getters = {

}

const mutations = {

  getTimes(state, payload) {
    state.loadingDataTimes = true;
    var vm = this;
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_API +
        "/time/list/all",
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.itemsDataTimes = response.data.data;
        state.loadingDataTimes = false;
      })
      .catch(function (error) {
        state.loadingDataTimes = false;
        console.log(error.response.data.message)
      });
  },

}

const actions = {
  getTimes({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getTimes", payload);
      resolve();
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}