import Vue from "vue";
import axios from "axios";
import router from "@/router";

const state = {
  loadingDataClubes: false,
  loadinggetDataClubes: false,
  loadinggetDataClubesLeague: false,
  itemsDataClubes: [],
  itemsDataClubesLeague: [],
  itemsDataClubesXlsxResult: [],

  clubes_name: null,
  clubes_search: null,
  clubes_acronym: null,
  clubes_id_pais: null,
  clubes_id_league: null,
  clubes_id_state: null,
  clubes_id_city: null,
  clubes_id_town: null,
  clubes_address: null,
  clubes_phone: null,
  clubes_email: null,
  clubes_website: null,
  clubes_password: null,
  clubes_president_name: null,
  clubes_president_email: null,
  clubes_president_phone: null,
  clubes_secretary_name: null,
  clubes_secretary_email: null,
  clubes_secretary_phone: null,
  clubes_responsible_name: null,
  clubes_responsible_email: null,
  clubes_responsible_phone: null,
  clubes_id_photo: null,
  clubes_id_status: false,
  clubes_file_modal_id: false,
  clubes_file_modal_name: null,

  itemsDataClubesXlsx: [],

  //MODAL
  clubes_modal_id_logo: false,
  clubes_modal_id_league: false,
  clubes_modal_import_data: false,
};

const mutations = {
  setClubes(state) {
    state.loadingDataClubes = true;
    var vm = this;
    var data = JSON.stringify({
      name: state.clubes_name,
      acronym: state.clubes_acronym,
      id_pais: state.clubes_id_pais,
      id_state: state.clubes_id_state,
      id_city: state.clubes_id_city,
      id_town: state.clubes_id_town,
      address: state.clubes_address,
      phone: state.clubes_phone,
      email: state.clubes_email,
      website: state.clubes_website,
      password: state.clubes_password,
      president_name: state.clubes_president_name,
      president_email: state.clubes_president_email,
      president_phone: state.clubes_president_phone,
      secretary_name: state.clubes_secretary_name,
      secretary_email: state.clubes_secretary_email,
      secretary_phone: state.clubes_secretary_phone,
      responsible_name: state.clubes_responsible_name,
      responsible_email: state.clubes_responsible_email,
      responsible_phone: state.clubes_responsible_phone,
      id_photo: state.clubes_id_photo,
      status: 1,
      id_league: state.clubes_id_league,
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_API + "/clubes/add",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        Vue.swal({
          position: "top-end",
          icon: "success",
          title: "clubes created successfully",
          showConfirmButton: true,
        });

        vm.commit("clubes/cleanClubes");

        state.loadingDataClubes = false;
      })
      .catch(function (error) {
        state.loadingDataClubes = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  setClubesMultiple(state, payload) {
    state.loadingDataClubes = true;
    var vm = this;
    var data = JSON.stringify({
      itemsclubes: state.itemsDataClubesXlsx,
      status: 1,
      id_organization: payload.id_organization,
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_API + "/clubes/multiple/add",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        Vue.swal({
          position: "top-end",
          icon: "success",
          title: "clubes imported successfully",
          showConfirmButton: true,
        });

        state.itemsDataClubesXlsxResult = response.data.success;

        vm.commit("clubes/getClubes", { id_org: payload.id_organization });
      })
      .catch(function (error) {
        state.loadingDataClubes = false;
        console.log(error.response.data.message);
      });
  },

  updateClubes(state, payload) {
    state.loadingDataClubes = true;
    var vm = this;
    var data = JSON.stringify({
      name: state.clubes_name,
      acronym: state.clubes_acronym,
      id_pais: state.clubes_id_pais,
      id_state: state.clubes_id_state,
      id_city: state.clubes_id_city,
      id_town: state.clubes_id_town,
      address: state.clubes_address,
      phone: state.clubes_phone,
      email: state.clubes_email,
      website: state.clubes_website,
      password: state.clubes_password,
      president_name: state.clubes_president_name,
      president_email: state.clubes_president_email,
      president_phone: state.clubes_president_phone,
      secretary_name: state.clubes_secretary_name,
      secretary_email: state.clubes_secretary_email,
      secretary_phone: state.clubes_secretary_phone,
      responsible_name: state.clubes_responsible_name,
      responsible_email: state.clubes_responsible_email,
      responsible_phone: state.clubes_responsible_phone,
      id_photo: state.clubes_id_photo,
      status: 1,
      id_league: state.clubes_id_league,
    });
    var config = {
      method: "put",
      url: process.env.VUE_APP_URL_API + "/clubes/update/" + payload.id,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        Vue.swal({
          position: "top-end",
          icon: "success",
          title: "Liga actualizada éxitosamente",
          showConfirmButton: true,
        });

        vm.commit("clubes/cleanClubes");
        router.back();

        state.loadingDataClubes = false;
      })
      .catch(function (error) {
        state.loadingDataClubes = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  deleteClubes(state, payload) {
    state.loadingDataClubes = true;
    var vm = this;
    var config = {
      method: "delete",
      url: process.env.VUE_APP_URL_API + "/clubes/delete/" + payload.id,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        Vue.swal({
          position: "top-end",
          icon: "success",
          title: "clubes successfully removed",
          showConfirmButton: true,
        });

        vm.commit("clubes/getClubes", { id_org: payload.id_org });

        state.loadingDataClubes = false;
      })
      .catch(function (error) {
        state.loadingDataClubes = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  getClubes(state, payload) {
    state.loadinggetDataClubes = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + "/clubes/list/" + payload.id_org,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.itemsDataClubes = response.data.data;

        state.loadinggetDataClubes = false;
      })
      .catch(function (error) {
        state.loadinggetDataClubes = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  getClubesLeague(state, payload) {
    state.loadinggetDataClubesLeague = true;
    var vm = this;
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_API +
        "/clubes/league/list/" +
        payload.id_league,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.itemsDataClubesLeague = response.data.data;
        state.loadinggetDataClubesLeague = false;
      })
      .catch(function (error) {
        state.loadinggetDataClubesLeague = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  getClubesOne(state, payload) {
    state.loadinggetDataClubes = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + "/clubes/list/one/" + payload.id,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.clubes_name = response.data.data.name;
        state.clubes_acronym = response.data.data.acronym;
        vm.commit("league/getLeague", {
          id_org: response.data.data.id_organization,
        });
        state.clubes_id_league = response.data.data.id_league;
        state.clubes_id_pais = response.data.data.id_pais;
        vm.commit("state/getState", {
          id_pais: response.data.data.id_organization,
        });
        state.clubes_id_state = response.data.data.id_state;
        vm.commit("city/getCity", {
          id_state: response.data.data.id_state,
        });
        state.clubes_id_city = response.data.data.id_city;
        vm.commit("town/getTown", {
          id_city: response.data.data.id_city,
        });
        state.clubes_id_town = response.data.data.id_town;
        state.clubes_address = response.data.data.address;
        state.clubes_phone = response.data.data.phone;
        state.clubes_email = response.data.data.email;
        state.clubes_website = response.data.data.website;
        state.clubes_password = response.data.data.password;
        state.clubes_president_name = response.data.data.president_name;
        state.clubes_president_email = response.data.data.president_email;
        state.clubes_president_phone = response.data.data.president_phone;
        state.clubes_secretary_name = response.data.data.secretary_name;
        state.clubes_secretary_email = response.data.data.secretary_email;
        state.clubes_secretary_phone = response.data.data.secretary_phone;
        state.clubes_responsible_name = response.data.data.responsible_name;
        state.clubes_responsible_email = response.data.data.responsible_email;
        state.clubes_responsible_phone = response.data.data.responsible_phone;
        state.clubes_id_photo = response.data.data.id_photo;
        state.clubes_file_modal_name = response.data.data.route_logo;
        state.clubes_id_status = false;

        state.loadinggetDataClubes = false;
      })
      .catch(function (error) {
        state.loadinggetDataClubes = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  cleanClubes(state) {
    state.clubes_name = null;
    state.clubes_acronym = null;
    state.clubes_id_pais = null;
    state.clubes_id_state = null;
    state.clubes_id_city = null;
    state.clubes_id_town = null;
    state.clubes_address = null;
    state.clubes_phone = null;
    state.clubes_email = null;
    state.clubes_website = null;
    state.clubes_password = null;
    state.clubes_president_name = null;
    state.clubes_president_email = null;
    state.clubes_president_phone = null;
    state.clubes_secretary_name = null;
    state.clubes_secretary_email = null;
    state.clubes_secretary_phone = null;
    state.clubes_responsible_name = null;
    state.clubes_responsible_email = null;
    state.clubes_responsible_phone = null;
    state.clubes_id_photo = null;
    state.clubes_id_status = false;
    state.clubes_file_modal_id = false;
    state.clubes_file_modal_name = null;
  },
};

const actions = {
  setClubes({ commit }) {
    return new Promise((resolve, reject) => {
      commit("setClubes");
      resolve();
    });
  },

  setClubesMultiple({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("setClubesMultiple", payload);
      resolve();
    });
  },

  updateClubes({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("updateClubes", payload);
      resolve();
    });
  },

  deleteClubes({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("deleteClubes", payload);
      resolve();
    });
  },

  cleanClubes({ commit }) {
    return new Promise((resolve, reject) => {
      commit("cleanClubes");
      resolve();
    });
  },

  getClubes({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getClubes", payload);
      resolve();
    });
  },

  getClubesLeague({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getClubesLeague", payload);
      resolve();
    });
  },

  getClubesOne({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getClubesOne", payload);
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
