import Vue from "vue";
import axios from 'axios'; 
import router from '@/router'

const state = {
    loadingDataCity: null,
    itemsDataCity: [],
}

const getters = {

}

const mutations = {

    getCity(state, payload) {
        state.loadingDataCity = true;
        var vm = this;
        var config = {
          method: "get",
          url:
            process.env.VUE_APP_URL_API +
            "/city/list/all/" +
            payload.id_state,
          headers: {
            "Content-Type": "application/json",
          },
        };
        axios(config)
          .then(function (response) {
            state.itemsDataCity = response.data.data;
            state.loadingDataCity = false;
          })
          .catch(function (error) {
            state.loadingDataCity = false;
           console.log(error.response.data.message)
          });
      },

}

const actions = {
    getCity({ commit }, payload) {
        return new Promise((resolve, reject) => {
          commit("getCity", payload);
          resolve();
        });
      },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}