import Vue from "vue";
import axios from "axios";
import router from "@/router";

const state = {
  loadingDataLeague: false,
  loadinggetDataLeague: false,
  itemsDataLeague: [],
  itemsDataLeagueXlsxResult: [],

  league_name: null,
  league_acronym: null,
  league_search: null,
  league_id_pais: null,
  league_id_state: null,
  league_id_city: null,
  league_id_town: null,
  league_address: null,
  league_phone: null,
  league_email: null,
  league_website: null,
  league_password: null,
  league_president_name: null,
  league_president_email: null,
  league_president_phone: null,
  league_secretary_name: null,
  league_secretary_email: null,
  league_secretary_phone: null,
  league_responsible_name: null,
  league_responsible_email: null,
  league_responsible_phone: null,
  league_id_photo: null,
  league_id_status: false,
  league_file_modal_id: false,
  league_file_modal_name: null,

  itemsDataLeagueXlsx: [],

  //MODAL
  league_modal_id_logo: false,
  league_modal_import_data: false,
};

const mutations = {
  setLeague(state, payload) {
    state.loadingDataLeague = true;
    var vm = this;
    var data = JSON.stringify({
      name: state.league_name,
      acronym: state.league_acronym,
      id_pais: state.league_id_pais,
      id_state: state.league_id_state,
      id_city: state.league_id_city,
      id_town: state.league_id_town,
      address: state.league_address,
      phone: state.league_phone,
      email: state.league_email,
      website: state.league_website,
      password: state.league_password,
      president_name: state.league_president_name,
      president_email: state.league_president_email,
      president_phone: state.league_president_phone,
      secretary_name: state.league_secretary_name,
      secretary_email: state.league_secretary_email,
      secretary_phone: state.league_secretary_phone,
      responsible_name: state.league_responsible_name,
      responsible_email: state.league_responsible_email,
      responsible_phone: state.league_responsible_phone,
      id_photo: state.league_id_photo,
      status: 1,
      id_organization: payload.id_organization,
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_API + "/league/add",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        Vue.swal({
          position: "top-end",
          icon: "success",
          title: "League created successfully",
          showConfirmButton: true,
        });

        vm.commit("league/cleanLeague");
        router.back();

        state.loadingDataLeague = false;
      })
      .catch(function (error) {
        state.loadingDataLeague = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  setLeagueMultiple(state, payload) {
    state.loadingDataLeague = true;
    var vm = this;
    var data = JSON.stringify({
      itemsLeague: state.itemsDataLeagueXlsx,
      status: 1,
      id_organization: payload.id_organization,
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_API + "/league/multiple/add",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        Vue.swal({
          position: "top-end",
          icon: "success",
          title: "League imported successfully",
          showConfirmButton: true,
        });

        state.itemsDataLeagueXlsxResult = response.data.success;

        vm.commit("league/getLeague", { id_org: payload.id_organization });
      })
      .catch(function (error) {
        state.loadingDataLeague = false;
        console.log(error.response.data.message);
      });
  },

  updateLeague(state, payload) {
    state.loadingDataLeague = true;
    var vm = this;
    var data = JSON.stringify({
      name: state.league_name,
      acronym: state.league_acronym,
      id_pais: state.league_id_pais,
      id_state: state.league_id_state,
      id_city: state.league_id_city,
      id_town: state.league_id_town,
      address: state.league_address,
      phone: state.league_phone,
      email: state.league_email,
      website: state.league_website,
      password: state.league_password,
      president_name: state.league_president_name,
      president_email: state.league_president_email,
      president_phone: state.league_president_phone,
      secretary_name: state.league_secretary_name,
      secretary_email: state.league_secretary_email,
      secretary_phone: state.league_secretary_phone,
      responsible_name: state.league_responsible_name,
      responsible_email: state.league_responsible_email,
      responsible_phone: state.league_responsible_phone,
      id_photo: state.league_id_photo,
      status: 1,
      id_organization: payload.id_organization,
    });
    var config = {
      method: "put",
      url: process.env.VUE_APP_URL_API + "/league/update/" + payload.id,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        Vue.swal({
          position: "top-end",
          icon: "success",
          title: "Liga actualizada éxitosamente",
          showConfirmButton: true,
        });

        vm.commit("league/cleanLeague");
        router.back();

        state.loadingDataLeague = false;
      })
      .catch(function (error) {
        state.loadingDataLeague = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  deleteLeague(state, payload) {
    state.loadingDataLeague = true;
    var vm = this;
    var config = {
      method: "delete",
      url: process.env.VUE_APP_URL_API + "/league/delete/" + payload.id,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        Vue.swal({
          position: "top-end",
          icon: "success",
          title: "League successfully removed",
          showConfirmButton: true,
        });

        vm.commit("league/getLeague", { id_org: payload.id_org });

        state.loadingDataLeague = false;
      })
      .catch(function (error) {
        state.loadingDataLeague = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  getLeague(state, payload) {
    state.loadinggetDataLeague = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + "/league/list/" + payload.id_org,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.itemsDataLeague = response.data.data;

        state.loadinggetDataLeague = false;
      })
      .catch(function (error) {
        state.loadinggetDataLeague = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  getLeagueOne(state, payload) {
    state.loadinggetDataLeague = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + "/league/list/one/" + payload.id,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.league_name = response.data.data.name;
        state.league_acronym = response.data.data.acronym;
        state.league_id_pais = response.data.data.id_pais;
        vm.commit("state/getState", {
          id_pais: response.data.data.id_pais,
        });
        state.league_id_state = response.data.data.id_state;
        vm.commit("city/getCity", {
          id_state: response.data.data.id_state,
        });
        state.league_id_city = response.data.data.id_city;
        vm.commit("town/getTown", {
          id_city: response.data.data.id_city,
        });
        state.league_id_town = response.data.data.id_town;
        state.league_address = response.data.data.address;
        state.league_phone = response.data.data.phone;
        state.league_email = response.data.data.email;
        state.league_website = response.data.data.website;
        state.league_password = response.data.data.password;
        state.league_president_name = response.data.data.president_name;
        state.league_president_email = response.data.data.president_email;
        state.league_president_phone = response.data.data.president_phone;
        state.league_secretary_name = response.data.data.secretary_name;
        state.league_secretary_email = response.data.data.secretary_email;
        state.league_secretary_phone = response.data.data.secretary_phone;
        state.league_responsible_name = response.data.data.responsible_name;
        state.league_responsible_email = response.data.data.responsible_email;
        state.league_responsible_phone = response.data.data.responsible_phone;
        state.league_id_photo = response.data.data.id_photo;
        state.league_file_modal_name = response.data.data.route_logo;
        state.league_id_status = false;

        state.loadinggetDataLeague = false;
      })
      .catch(function (error) {
        state.loadinggetDataLeague = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  cleanLeague(state) {
    state.league_name = null;
    state.league_acronym = null;
    state.league_id_pais = null;
    state.league_id_state = null;
    state.league_id_city = null;
    state.league_id_town = null;
    state.league_address = null;
    state.league_phone = null;
    state.league_email = null;
    state.league_website = null;
    state.league_password = null;
    state.league_president_name = null;
    state.league_president_email = null;
    state.league_president_phone = null;
    state.league_secretary_name = null;
    state.league_secretary_email = null;
    state.league_secretary_phone = null;
    state.league_responsible_name = null;
    state.league_responsible_email = null;
    state.league_responsible_phone = null;
    state.league_id_photo = null;
    state.league_id_status = false;
    state.league_file_modal_id = false;
    state.league_file_modal_name = null;
  },
};

const actions = {
  setLeague({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("setLeague", payload);
      resolve();
    });
  },

  setLeagueMultiple({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("setLeagueMultiple", payload);
      resolve();
    });
  },

  updateLeague({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("updateLeague", payload);
      resolve();
    });
  },

  deleteLeague({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("deleteLeague", payload);
      resolve();
    });
  },

  cleanLeague({ commit }) {
    return new Promise((resolve, reject) => {
      commit("cleanLeague");
      resolve();
    });
  },

  getLeague({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getLeague", payload);
      resolve();
    });
  },

  getLeagueOne({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getLeagueOne", payload);
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
