import Vue from "vue";
import axios from "axios";
import router from "@/router";

const state = {
  loadingDataTest: null,
  itemsDataTest: [],
  itemsDataTestTypeSegments: [],

  loadingDataTestRelational: false,
  itemsDataTestRelational: [],

  loadingDeleteTestEvent: false,
  itemsDataDeleteTestEvent: [],

  loadingEventsTest: false,
  itemsDataEventsTest: [],

  loadingSetInscriptions: false,
  itemsSetInscriptions: [],
};

const getters = {};

const mutations = {
  async getTest(state, payload) {
    state.loadingDataTest = true;
    var vm = this;
    let data = {
      id_segments: payload.id_segments,
      id_category: payload.id_category,
    };
    var config = {
      method: "post",
      url:
        process.env.VUE_APP_URL_API +
        "/test/list/" +
        payload.id_org +
        "/" +
        payload.id_event,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(function (response) {
        state.itemsDataTest = response.data.data;
        state.loadingDataTest = false;
      })
      .catch(function (error) {
        state.loadingDataTest = false;
        console.log(error.response.data.message);
      });
  },

  getTestTypeSegments(state, payload) {
    state.loadingDataTest = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + "/type/segments/list/all",
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.itemsDataTestTypeSegments = response.data.data;
        state.loadingDataTest = false;
      })
      .catch(function (error) {
        state.loadingDataTest = false;
        console.log(error.response.data.message);
      });
  },

  async setTestEvent(state, payload) {
    state.loadingDataTest = true;
    var vm = this;
    let data = {
      id_event: payload.id_event,
      id_test: payload.id_test,
      id_test_segmented: payload.id_test_segmented,
      id_category: payload.id_category,
      id_gender: payload.id_gender,
      status_results: 0,
      status: 1,
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_API + "/test/add",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(async function (response) {
        await vm
          .dispatch("tests/getTestRelationals", {
            id_org: payload.id_org,
            id_event: payload.id_event,
            id_segment: payload.id_test_segmented,
            id_gender: payload.id_gender,
            id_category: payload.id_category,
          })
          .then((response) => {
            state.itemsDataTest = response;
          });
        state.loadingDataTest = false;
      })
      .catch(function (error) {
        state.loadingDataTest = false;
        console.log(error.response.data.message);
      });
  },
};

const actions = {
  getTest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getTest", payload);
      resolve();
    });
  },

  getTestRelationals({ state }, payload) {
    return new Promise((resolve) => {
      state.loadingDataTestRelational = true;
      var vm = this;
      let data = {
        id_category: payload.id_category,
        id_gender: payload.id_gender,
        id_segment: payload.id_segment,
        id_event: payload.id_event,
      };
      var config = {
        method: "post",
        url:
          process.env.VUE_APP_URL_API + "/test/relationals/" + payload.id_org,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          resolve(response.data.data);
          state.loadingDataTestRelational = false;
        })
        .catch(function (error) {
          state.loadingDataTestRelational = false;
          console.log(error.response.data.message);
        });
    });
  },

  deleteTestEvent({ state }, payload) {
    return new Promise((resolve) => {
      state.loadingDeleteTestEvent = true;
      var vm = this;
      var config = {
        method: "delete",
        url: process.env.VUE_APP_URL_API + "/test/event/delete/" + payload.id,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then(async function (response) {
          resolve(response.data.data);
          await vm
            .dispatch("tests/getTestRelationals", {
              id_org: payload.id_org,
              id_event: payload.id_event,
              id_segment: payload.id_test_segmented,
              id_gender: payload.id_gender,
              id_category: payload.id_category,
            })
            .then((response) => {
              state.itemsDataTest = response;
            });
          state.loadingDeleteTestEvent = false;
        })
        .catch(function (error) {
          state.loadingDeleteTestEvent = false;
          console.log(error.response.data.message);
        });
    });
  },

  getEventsTest({ state }, payload) {
    return new Promise((resolve) => {
      state.loadingEventsTest = true;
      var vm = this;
      var config = {
        method: "get",
        url:
          process.env.VUE_APP_URL_API + "/events/test/list/" + payload.id_event,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then(function (response) {
          resolve(response.data.data);
          state.loadingEventsTest = false;
        })
        .catch(function (error) {
          state.loadingEventsTest = false;
          console.log(error.response.data.message);
        });
    });
  },

  getEventsTestCate({ state }, payload) {
    return new Promise((resolve) => {
      state.loadingEventsTest = true;
      var vm = this;
      let data = {
        category: payload.id_category,
        gender: payload.id_gender,
        id_athlete: payload.id_athlete
      };
      var config = {
        method: "post",
        url:
          process.env.VUE_APP_URL_API + "/events/test/list/" + payload.id_event,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          resolve(response.data.data);
          state.loadingEventsTest = false;
        })
        .catch(function (error) {
          state.loadingEventsTest = false;
          console.log(error.response.data.message);
        });
    });
  },

  getTestTypeSegments({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getTestTypeSegments", payload);
      resolve();
    });
  },

  setTestEvent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("setTestEvent", payload);
      resolve();
    });
  },

  updateTestEvent({ state }, payload) {
    return new Promise((resolve, reject) => {
      state.loadingDataTest = true;
      var vm = this;
      let data = {
        id_turn: payload.id_turn,
        id_event_time: payload.id_time,
        time: payload.time,
      };
      var config = {
        method: "put",
        url:
          process.env.VUE_APP_URL_API + "/test/event/update/" + payload.id_test,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(async function (response) {
          await vm
            .dispatch("tests/getEventsTest", {
              id_event: payload.id_event,
            })
            .then((response) => {
              state.itemsDataEventsTest = response;
            });
          resolve(response.data.data);
          state.loadingDataTest = false;
        })
        .catch(function (error) {
          state.loadingDataTest = false;
          console.log(error.response.data.message);
        });
    });
  },

  setInscriptions({ state }, payload) {
    return new Promise((resolve, reject) => {
      state.loadingSetInscriptions = true;
      var vm = this;
      let data = {
        id_athlete: payload.id_athlete,
        id_test: payload.id_test,
        status: payload.status,
      };
      var config = {
        method: "post",
        url: process.env.VUE_APP_URL_API + "/test/inscriptions",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(async function (response) {
          resolve(response.data.data);
          setTimeout(()=>{
            state.loadingSetInscriptions = false;
          },2000)
        })
        .catch(function (error) {
          state.loadingSetInscriptions = false;
          console.log(error.response.data.message);
        });
    });
  },

  deleteInscriptions({ state }, payload) {
    return new Promise((resolve, reject) => {
      state.loadingSetInscriptions = true;
      var vm = this;
      var config = {
        method: "delete",
        url: process.env.VUE_APP_URL_API + "/test/inscriptions/" + payload.id,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then(async function (response) {
          resolve(response.data.data);
          state.loadingSetInscriptions = false;
        })
        .catch(function (error) {
          state.loadingSetInscriptions = false;
          console.log(error.response.data.message);
        });
    });
  },

  getReportInscriptions({ state }, payload) {
    return new Promise((resolve, reject) => {
      state.loadingSetInscriptions = true;
      var vm = this;
      var config = {
        method: "get",
        url:
          process.env.VUE_APP_URL_API +
          `/test/report/${payload.idPeople}/${payload.idEvent}`,
        responseType: "blob",
        headers: {
          "Content-Type": "application/pdf",
          Accept: "application/pdf",
        },
      };
      axios(config)
        .then(async function (response) {
          const url = URL.createObjectURL(response.data);
          const a = document.createElement("a");
          a.style = "display: none";
          a.href = url;
          a.download = payload.filename + ".pdf";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);

          /*  resolve(response.data.data);
          state.loadingSetInscriptions = false; */
        })
        .catch(function (error) {
          state.loadingSetInscriptions = false;
          console.log(error.response.data.message);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
