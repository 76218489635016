import Vue from "vue";
import axios from "axios";
import router from "@/router";

const state = {
  loadingDataTypeDocuments: null,
  itemsDataTypeDocuments: [],
  loadingDataState: null,
  itemsDataState: [],
};

const getters = {};

const mutations = {
  getTypeDocuments(state) {
    state.loadingDataTypeDocuments = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + "/typedocument/list/all",
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.itemsDataTypeDocuments = response.data.data;
        state.loadingDataTypeDocuments = false;
      })
      .catch(function (error) {
        state.loadingDataTypeDocuments = false;
        console.log(error.response.data.message)
      });
  },
};

const actions = {
  getTypeDocuments({ commit }) {
    return new Promise((resolve, reject) => {
      commit("getTypeDocuments");
      resolve();
    });
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
