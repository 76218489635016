import Vue from "vue";
import axios from 'axios'; 
import router from '@/router'

const state = {
    loadingDataTown: null,
    itemsDataTown: [],
}

const getters = {

}

const mutations = {

    getTown(state, payload) {
        state.loadingDataTown = true;
        var vm = this;
        var config = {
          method: "get",
          url:
            process.env.VUE_APP_URL_API +
            "/town/list/all/" +
            payload.id_city,
          headers: {
            "Content-Type": "application/json",
          },
        };
        axios(config)
          .then(function (response) {
            state.itemsDataTown = response.data.data;
            state.loadingDataTown = false;
          })
          .catch(function (error) {
            state.loadingDataTown = false;
            console.log(error.response.data.message)
          });
      },

}

const actions = {
    getTown({ commit }, payload) {
        return new Promise((resolve, reject) => {
          commit("getTown", payload);
          resolve();
        });
      },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}