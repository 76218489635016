import Vue from "vue";
import axios from "axios";
import router from "@/router";

const state = {
  loadingDataCategories: false,
  itemsDataCategories: [],

  loadingDataCategoryLevel: false,

  dataCategoryLevel: [],
};

const mutations = {
  setCategories(state, payload) {
    state.loadingDataCategories = true;
    var vm = this;
    var data = JSON.stringify({
      itemsRoles: [
        {
          id_role: 3,
        },
      ],
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_API + "/people/add",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        Vue.swal({
          position: "top-end",
          icon: "success",
          title: "athlete created successfully",
          showConfirmButton: true,
        });

        vm.commit("athletes/cleanCategories");
        router.back();
        state.loadingDataCategories = false;
      })
      .catch(function (error) {
        state.loadingDataCategories = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  setCategoriesMultiple(state, payload) {
    state.loadingDataCategories = true;
    var vm = this;
    var data = JSON.stringify({
      itemsathletes: state.itemsDataCategoriesXlsx,
      status: 1,
      id_organization: payload.id_organization,
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_API + "/athletes/multiple/add",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        Vue.swal({
          position: "top-end",
          icon: "success",
          title: "athletes imported successfully",
          showConfirmButton: true,
        });

        state.itemsDataCategoriesXlsxResult = response.data.success;

        vm.commit("athletes/getCategories", {
          id_org: payload.id_organization,
        });
      })
      .catch(function (error) {
        state.loadingDataCategories = false;
        console.log(error.response.data.message);
      });
  },

  updateCategories(state, payload) {
    state.loadingDataCategories = true;
    var vm = this;
    var data = JSON.stringify({
      id_type_document: state.athletes_type_document,
      document: state.athletes_document,
      names: state.athletes_names,
      last_name: state.athletes_father_last_name,
      mother_last_name: state.athletes_mother_last_name,
      id_gender: state.athletes_gender,
      birthday: state.athletes_date_of_bird,
      id_pais_location: state.athletes_id_pais,
      id_state_location: state.athletes_id_state,
      id_city_location: state.athletes_id_city,
      id_town_location: state.athletes_id_town,
      address_location: state.athletes_address,
      height: state.athletes_physical_height,
      weight: state.athletes_physical_weight,
      footwear: state.athletes_sizes_footwear,
      pole_size: state.athletes_sizes_pole,
      pants_size: state.athletes_sizes_pants,
      mail: state.athletes_email,
      phone: state.athletes_phone,
      status: 1,
      id_organization: payload.id_org,
      id_photo: state.athletes_id_photo,
      id_federation: state.athletes_entitie_id_federation,
      id_league: state.athletes_entitie_id_league,
      id_club: state.athletes_entitie_id_club,
      itemsRoles: [
        {
          id_people: payload.id,
          id_role: 3,
        },
      ],
    });
    var config = {
      method: "put",
      url: process.env.VUE_APP_URL_API + "/people/update/" + payload.id,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        Vue.swal({
          position: "top-end",
          icon: "success",
          title: "Atleta actualizada éxitosamente",
          showConfirmButton: true,
        });

        vm.commit("athletes/cleanCategories");
        router.back();

        state.loadingDataCategories = false;
      })
      .catch(function (error) {
        state.loadingDataCategories = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  deleteCategories(state, payload) {
    state.loadingDataCategories = true;
    var vm = this;
    let data = {
      status: 0,
    };
    var config = {
      method: "delete",
      url: process.env.VUE_APP_URL_API + "/people/delete/" + payload.id,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        Vue.swal({
          position: "top-end",
          icon: "success",
          title: "athletes successfully removed",
          showConfirmButton: true,
        });

        vm.commit("athletes/getCategories", { id_org: payload.id_org });

        state.loadingDataCategories = false;
      })
      .catch(function (error) {
        state.loadingDataCategories = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  getCategories(state, payload) {
    state.loadinggetDataCategories = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + "/categories/list/" + payload.status,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.itemsDataCategories = response.data.data;

        state.loadinggetDataCategories = false;
      })
      .catch(function (error) {
        state.loadinggetDataCategories = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  getCategoryLevel(state, payload) {
    state.loadingDataCategoryLevel = true;
    var vm = this;
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_API + "/categories/level/" + payload.idCategory,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.dataCategoryLevel = response.data.data;

        state.loadingDataCategoryLevel = false;
      })
      .catch(function (error) {
        state.loadingDataCategoryLevel = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  getCategoriesOne(state, payload) {
    state.loadinggetDataCategories = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + "/people/one/" + payload.id,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.athletes_type_document = response.data.data[0].document.id;
        state.athletes_document = response.data.data[0].document.number;
        state.athletes_names = response.data.data[0].names;
        state.athletes_father_last_name = response.data.data[0].last_name;
        state.athletes_mother_last_name =
          response.data.data[0].mother_last_name;
        state.athletes_gender = response.data.data[0].gender.id;
        state.athletes_date_of_bird = response.data.data[0].birthday;
        state.athletes_id_pais = response.data.data[0].location.pais.id;
        vm.commit("state/getState", {
          id_pais: response.data.data[0].location.pais.id,
        });
        state.athletes_id_state = response.data.data[0].location.state.id;
        vm.commit("city/getCity", {
          id_state: response.data.data[0].location.state.id,
        });
        state.athletes_id_city = response.data.data[0].location.city.id;
        vm.commit("town/getTown", {
          id_city: response.data.data[0].location.city.id,
        });
        state.athletes_id_town = response.data.data[0].location.town.id;
        state.athletes_address = response.data.data[0].location.address;
        state.athletes_physical_height = response.data.data[0].sizes.height;
        state.athletes_physical_weight = response.data.data[0].sizes.weight;
        state.athletes_sizes_footwear = response.data.data[0].sizes.footwear;
        state.athletes_sizes_pole = response.data.data[0].sizes.pole_size;
        state.athletes_sizes_pants = response.data.data[0].sizes.pants_size;
        state.athletes_email = response.data.data[0].contact.mail;
        state.athletes_phone = response.data.data[0].contact.phone;
        state.athletes_entitie_id_federation =
          response.data.data[0].organization.id_federation;
        state.athletes_entitie_id_league =
          response.data.data[0].organization.id_league;
        state.athletes_entitie_id_club =
          response.data.data[0].organization.id_club;
        state.athletes_file_modal_name = response.data.data[0].photo.route;
      })
      .catch(function (error) {
        state.loadinggetDataCategories = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  cleanCategories(state) {
    state.athletes_type_document = null;
    state.athletes_document = null;
    state.athletes_names = null;
    state.athletes_father_last_name = null;
    state.athletes_mother_last_name = null;
    state.athletes_date_of_bird = null;
    state.athletes_id_pais = null;
    state.athletes_id_state = null;
    state.athletes_id_city = null;
    state.athletes_id_town = null;
    state.athletes_address = null;
    state.athletes_phone = null;
    state.athletes_email = null;
    state.athletes_gender = null;
    state.athletes_physical_height = null;
    state.athletes_physical_weight = null;
    state.athletes_number_people = null;
    state.athletes_sizes_footwear = null;
    state.athletes_sizes_pole = null;
    state.athletes_sizes_pants = null;
    state.athletes_entitie_id_federation = null;
    state.athletes_entitie_id_league = null;
    state.athletes_entitie_id_club = null;
    state.athletes_file_modal_id = false;
    state.athletes_file_modal_name = null;
    state.itemsDataCategoriesXlsx = [];
  },
};

const actions = {
  setCategories({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("setCategories", payload);
      resolve();
    });
  },

  setCategoriesMultiple({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("setCategoriesMultiple", payload);
      resolve();
    });
  },

  updateCategories({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("updateCategories", payload);
      resolve();
    });
  },

  deleteCategories({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("deleteCategories", payload);
      resolve();
    });
  },

  cleanCategories({ commit }) {
    return new Promise((resolve, reject) => {
      commit("cleanCategories");
      resolve();
    });
  },

  getCategories({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getCategories", payload);
      resolve();
    });
  },

  getCategoryLevel({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getCategoryLevel", payload);
      resolve();
    });
  },

  getCategoriesOne({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getCategoriesOne", payload);
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
