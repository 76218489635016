import Vue from "vue";
import axios from 'axios'; 
import router from '@/router'

const state = {
    loginUser: null,
    loginPassword: null,

    loadingDataPeople: false,

}

const getters = {

}

const mutations = {

    validateLogin(state) {
        state.loadingDataPeople = true
        var vm = this;
        var data = JSON.stringify({
          username: state.loginUser,
          password: state.loginPassword,
          status: 1
        });
        var config = {
          method: 'post',
          url: process.env.VUE_APP_URL_API + "/loggin",
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };
        axios(config)
          .then(function (response) {
            localStorage.setItem('dataPeople', JSON.stringify(response.data.data[0]));
            vm.state.dataPeople = JSON.stringify(response.data.data[0]);
            router.push('/dashboard');
            Vue.swal(
              {
                position: 'top-end',
                icon: 'success',
                title: 'Bienvenido',
                showConfirmButton: false,
                timer: 1500
              }
            );
  
            state.loadingDataPeople = false
           
          })
          .catch(function (error) {
            state.loadingDataPeople = false
            Vue.swal(
              {
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.message,
  
              }
            );
          });
      },

}

const actions = {

    getValidateLogin({ commit }) {
        return new Promise((resolve, reject) => {
          commit('validateLogin');
          resolve();
        })
      },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}