<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    this.$store.state.dataPeople = JSON.parse(
      localStorage.getItem("dataPeople")
    );
  },
  mounted() {
    if (screen.width < 800) this.$store.state.isDevice = true;
    else this.$store.state.isDevice = false;
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Golos+Text:wght@400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  font-family: "Roboto", sans-serif !important;
}

#app {
  color: #29386b;
}

label {
  font-weight: 500;
}
</style>
