import Vue from "vue";
import axios from "axios";
import router from "@/router";

const state = {
  loadingDataPais: null,
  itemsDataPais: [],
  loadingDataState: null,
  itemsDataState: [],
};

const getters = {};

const mutations = {
  getPais(state) {
    state.loadingDataPais = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + "/pais/list/all",
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.itemsDataPais = response.data.data;
        state.loadingDataPais = false;
      })
      .catch(function (error) {
        state.loadingDataPais = false;
        console.log(error.response.data.message)
      });
  },
};

const actions = {
  getPais({ commit }) {
    return new Promise((resolve, reject) => {
      commit("getPais");
      resolve();
    });
  },
  
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
