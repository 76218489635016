import Vue from "vue";
import axios from 'axios'; 
import router from '@/router'

const state = {
    loadingDataState: null,
    itemsDataState: [],
}

const getters = {

}

const mutations = {

    getState(state, payload) {
        state.loadingDataState = true;
        var vm = this;
        var config = {
          method: "get",
          url:
            process.env.VUE_APP_URL_API +
            "/state/list/all/" +
            payload.id_pais,
          headers: {
            "Content-Type": "application/json",
          },
        };
        axios(config)
          .then(function (response) {
            state.itemsDataState = response.data.data;
            state.loadingDataState = false;
          })
          .catch(function (error) {
            state.loadingDataState = false;
            console.log(error.response.data.message)
          });
      },

}

const actions = {
    getState({ commit }, payload) {
        return new Promise((resolve, reject) => {
          commit("getState", payload);
          resolve();
        });
      },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}