import Vue from "vue";
import axios from 'axios';
import router from '@/router'
import { mapState } from "vuex";
import league from "../../league";
import clubes from "../../clubes";
import athletes from "../../athletes";

const state = {
  loadingDataFile: null,
  itemsDataFile: [],
}

const mutations = {

  async setFile(state, payload) {

    state.loadingDataFile = true
    let vm = this;

    const FormData = require('form-data');

    let data = new FormData();
    data.append('file', payload.app_upload_file);

    var config = {
      method: 'post',
      url: process.env.VUE_APP_URL_API + "/upload/file/single",
      headers: {
        'dirname': payload.file_dirname,
        'filename': payload.file_filename,
        'code': payload.file_filename,
        'name': payload.file_name,
        'description': payload.file_description,
        'original_name': payload.file_original_name,
        'size': payload.file_size,
        'type': payload.file_type,
        'extension': payload.file_extension,
        'status': payload.file_status,
        'route': payload.file_filename,

      },
      data: data
    };
    await axios(config)
      .then(function (response) {
        if (payload.file_channel == 'league') {
          state.league.league_id_photo = response.data.data.id
          state.league.league_file_modal_name = response.data.data.route
          state.league.league_modal_id_logo = false;
        } else if (payload.file_channel == 'clubes') {
          state.clubes.clubes_id_photo = response.data.data.id
          state.clubes.clubes_file_modal_name = response.data.data.route
          state.clubes.clubes_modal_id_logo = false;
        } else if (payload.file_channel == 'athletes') {
          state.athletes.athletes_id_photo = response.data.data.id
          state.athletes.athletes_file_modal_name = response.data.data.route
          state.athletes.athletes_modal_id_photo = false;
        }

        //state.itemsDataFile = response.data.data;

        Vue.swal({
          title: 'Archivo cargado exitosamente',
          icon: 'success',
          timer: 1500
          // all of other options may go here
        });

        state.loadingDataFile = false;
      })
      .catch(function (error) {

        Vue.swal(
          {
            icon: 'error',
            title: 'Oops...',
            text: error.response.data.message,
          }
        );
        state.loadingDataFile = false;
      });
  },

}

const actions = {
  setFile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("setFile", payload);
      resolve();
    });
  },

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    league,
    clubes,
    athletes
  }
}