import Vue from "vue";
import axios from "axios";
import router from "@/router";

const state = {
  modal_calendar_registered: false,
  time: null,
  id_time: null,
  item_selected: [],
};

const getters = {};

const mutations = {};

const actions = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
