import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/dashboard",
    name: "main",
    component: () => import("../views/Dashboard/MainView.vue"),
    children: [
      {
        path: "/dashboard/federation",
        name: "mainFd",
        component: () => import("../views/Dashboard/Federation/mainFdView.vue"),
      },
      {
        path: "/dashboard/league",
        name: "mainLg",
        component: () => import("../views/Dashboard/League/mainLgView.vue"),
      },
      {
        path: "/dashboard/league/form",
        name: "formLg",
        component: () => import("../views/Dashboard/League/formLgView.vue"),
      },
      {
        path: "/dashboard/league/form/edit/:id",
        name: "formEditLg",
        component: () => import("../views/Dashboard/League/formLgView.vue"),
      },
      {
        path: "/dashboard/league/form/:id_view/:id",
        name: "formViewLg",
        component: () => import("../views/Dashboard/League/formLgView.vue"),
      },
      {
        path: "/dashboard/clubes",
        name: "mainCb",
        component: () => import("../views/Dashboard/Clubes/mainCbView.vue"),
      },
      {
        path: "/dashboard/clubes/form",
        name: "formCb",
        component: () => import("../views/Dashboard/Clubes/formCbView.vue"),
      },
      {
        path: "/dashboard/clubes/form/edit/:id",
        name: "formEditCb",
        component: () => import("../views/Dashboard/Clubes/formCbView.vue"),
      },
      {
        path: "/dashboard/clubes/form/:id_view/:id",
        name: "formViewCb",
        component: () => import("../views/Dashboard/Clubes/formCbView.vue"),
      },
      {
        path: "/dashboard/athletes",
        name: "mainAt",
        component: () => import("../views/Dashboard/Athletes/mainAtView.vue"),
      },
      {
        path: "/dashboard/athletes/:name_club",
        name: "mainAt",
        component: () => import("../views/Dashboard/Athletes/mainAtView.vue"),
      },
      {
        path: "/dashboard/athletes/form/add",
        name: "formAt",
        component: () => import("../views/Dashboard/Athletes/formAtView.vue"),
      },
      {
        path: "/dashboard/athletes/form/edit/:id",
        name: "formEditAt",
        component: () => import("../views/Dashboard/Athletes/formAtView.vue"),
      },
      {
        path: "/dashboard/athletes/form/:id_view/:id",
        name: "formViewAt",
        component: () => import("../views/Dashboard/Athletes/formAtView.vue"),
      },
      {
        path: "/dashboard/events/main",
        name: "mainEvents",
        component: () => import("../views/Dashboard/Events/mainEvView.vue"),
      },
      {
        path: "/dashboard/events/form",
        name: "formEvents",
        component: () => import("../views/Dashboard/Events/formEvView.vue"),
      },
      {
        path: "/dashboard/events/tests/:id_event",
        name: "formEventsTests",
        component: () => import("../views/Dashboard/Events/formEvView.vue"),
      },
      {
        path: "/dashboard/test/main",
        name: "mainTest",
        component: () => import("../views/Dashboard/Test/mainTView.vue"),
      },
      {
        path: "/dashboard/calendar/main/:id_event",
        name: "mainCalendar",
        component: () =>
          import("../views/Dashboard/Calendar/mainCalendarView.vue"),
      },
      {
        path: "/dashboard/inscriptions/main",
        name: "mainInscriptions",
        component: () =>
          import("../views/Dashboard/Inscriptions/mainInsView.vue"),
      },
      {
        path: "/dashboard/inscriptions/main/:id_event",
        name: "mainInscriptionsEvent",
        component: () =>
          import("../views/Dashboard/Inscriptions/mainInsView.vue"),
      },
      {
        path: "/dashboard/laid/main/",
        name: "mainLaid",
        component: () =>
          import("../views/Dashboard/Laid/mainLaidView.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
