import Vue from "vue";
import axios from "axios";
import router from "@/router";

const state = {
  id_league: null,
  id_club: null,
  id_athlete: null,
  id_event_selected: null,
  modal_select_event: true,

  inscriptions_modal_change_level: false,
  inscriptions_modal_upload_level: false,

  id_category_selected: null,

  spinner_select_event: true,

  name_category: null,

  itemsDataAthlete: [],
  itemsDataTests: [],
  test_selected: [],

  dataEvents: [],

  itemsInscriptionsAthlete: [],
  itemsInscriptionsList: [],
  dataInscriptionDelete: [],
  loadingDataInscription: false,
  loadingDataPln: false,
  loadingDeleteInscription: false
};

const getters = {};

const mutations = {
  getInscriptions(state, payload) {
    state.loadingDataGender = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + `/inscriptions/${payload.id_athlete}/${payload.id_event}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.itemsInscriptionsAthlete =  response.data.data
      })
      .catch(function (error) {
        state.loadingDataGender = false;
        console.log(error.response.data.message)
      });
  },

  getInscriptionsList(state, payload) {
    state.loadingDataInscription = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + `/inscriptions/${payload.id_event}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.loadingDataInscription = false;
        state.itemsInscriptionsList =  response.data.data
      })
      .catch(function (error) {
        state.loadingDataInscription = false;
        console.log(error.response.data.message)
      });
  },

  delInscription(state, payload) {
    state.loadingDeleteInscription = true;
    var vm = this;
    var config = {
      method: "delete",
      url: process.env.VUE_APP_URL_API + `/inscriptions/${payload.id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        Vue.swal({
          position: "center",
          icon: "success",
          title: "Inscripción eliminada exitosamente",
          showConfirmButton: true,
        });

        vm.commit("inscriptions/getInscriptionsList", { id_event: payload.id_event });
        state.loadingDeleteInscription = false;
        state.dataInscriptionDelete =  response.data.data
      })
      .catch(function (error) {
        state.loadingDeleteInscription = false;
        console.log(error.response.data.message)
      });
  },

  getStartList(state, payload) {
    state.loadingDataInscription = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + `/inscriptionsstart/startlist/${payload.id_event}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.loadingDataInscription = false;
        window.open(process.env.VUE_APP_URL_API + response.data.data.route, 'nna', 'height=1105,width=833')
      })
      .catch(function (error) {
        state.loadingDataInscription = false;
        console.log(error.response.data.message)
      });
  },

  getResults(state, payload) {
    state.loadingDataInscription = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + `/inscriptionsres/res/${payload.id_event}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.loadingDataInscription = false;
        window.open(process.env.VUE_APP_URL_API + response.data.data.route, 'nna', 'height=1105,width=833')
      })
      .catch(function (error) {
        state.loadingDataInscription = false;
        console.log(error.response.data.message)
      });
  },

  getEvt(state, payload) {
    state.loadingDataInscription = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + `/inscriptionsevt/evt/${payload.id_event}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function(response) {
        state.loadingDataInscription = false;
        const evt = window.location.href = process.env.VUE_APP_URL_API + response.data.data.routeEvt
        setTimeout(()=> {
          window.location.href = process.env.VUE_APP_URL_API + response.data.data.routePpl
        },3000)
        //window.location.href = process.env.VUE_APP_URL_API + response.data.data.routePpl
      })
      .catch(function (error) {
        state.loadingDataInscription = false;
        console.log(error.response.data.message)
      });
  },

  getPla(state, payload) {
    state.loadingDataPln = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + `/inscriptionspla/pla/${payload.id_event}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function(response) {
        state.loadingDataPln = false;
        response.data.data.map((d)=> {
          setTimeout(()=> {
            window.open(process.env.VUE_APP_URL_API + d.route, d.route , 'height=1105,width=833')
            //window.location.href = process.env.VUE_APP_URL_API + d.route
          },1000)
        })
       
      })
      .catch(function (error) {
        state.loadingDataPln = false;
        console.log(error.response.data.message)
      });
  },
};


const actions = {
  getReportInscriptionsLeague({ state }, payload) {
    return new Promise((resolve, reject) => {
      state.loadingSetInscriptions = true;
      var vm = this;
      var config = {
        method: "get",
        url:
          process.env.VUE_APP_URL_API +
          `/test/inscriptions/report/league/${payload.idEvent}/${payload.idLeague}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then(async function (response) {
          window.open(process.env.VUE_APP_URL_API + response.data.data.route, 'nna', 'height=1105,width=833')

          /*  resolve(response.data.data);
          state.loadingSetInscriptions = false; */
        })
        .catch(function (error) {
          state.loadingSetInscriptions = false;
          console.log(error.response.data.message);
        });
    });
  },

  getInscriptions({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getInscriptions", payload);
      resolve();
    });
  },

  getInscriptionsList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getInscriptionsList", payload);
      resolve();
    });
  },

  delInscription({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("delInscription", payload);
      resolve();
    });
  },

  getStartList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getStartList", payload);
      resolve();
    });
  },

  getEvt({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getEvt", payload);
      resolve();
    });
  },

  getPla({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getPla", payload);
      resolve();
    });
  },

  getResults({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getResults", payload);
      resolve();
    });
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
