import Vue from "vue";
import axios from "axios";
import router from "@/router";

const state = {
  loadingDataTurn: null,
  itemsDataTurn: [],
  loadingDataState: null,
  itemsDataState: [],
};

const getters = {};

const mutations = {
  getTurn(state) {
    state.loadingDataTurn = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + "/turn/list/all",
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.itemsDataTurn = response.data.data;
        state.loadingDataTurn = false;
      })
      .catch(function (error) {
        state.loadingDataTurn = false;
        console.log(error.response.data.message)
      });
  },
};

const actions = {
  getTurn({ commit }) {
    return new Promise((resolve, reject) => {
      commit("getTurn");
      resolve();
    });
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
